import React, { useEffect } from "react";
import "./index.css";
import anime from "animejs";
import ContactForm from "./form";
import { Outlet } from "react-router-dom";

export default function Contact() {
	useEffect(() => {
		var textWrapper = document.querySelector(".ml6 .letters");
		textWrapper.innerHTML = textWrapper.textContent.replace(
			/\S/g,
			"<span class='letter'>$&</span>"
		);

		anime
			.timeline({ loop: true })
			.add({
				targets: ".ml6 .letter",
				translateY: ["1.1em", 0],
				translateZ: 0,
				duration: 750,
				delay: (el, i) => 50 * i,
			})
			.add({
				targets: ".ml6",
				opacity: 0,
				duration: 1000,
				easing: "easeOutExpo",
				delay: 1000,
			});
	}, []);
	function CallSend() {}
	return (
		<div className="position-relative">
			<div
				class="third navyblue-text light-skyblue-background"
				id="contact-us"
				title="contact-us"
			>
				<div class="thirdd d-flex flex-column flex-lg-row align-items-center pb-3 justify-content-around">
					<div class="cc">
						<h1 class="ml6 position-relative">
							<span class="text-wrapper">
								<span class="letters ">BUY NOW</span>
							</span>
						</h1>
						<h2>
							<span class="">CON</span>TACT US
						</h2>
						<p>We are ready, willing to assist you.</p>
						<div class="last">
							<span class="blackspan">
								<i
									class="fa
                                fa-phone skyblue-text me-2"
								></i>
								Phone:
							</span>{" "}
							<a
								href="tel:+2348179999953"
								className="navyblue-text"
							>
								+234-817-999-9953
							</a>{" "}
							<br />
							<a
								href="tel:+2348179999961"
								className="navyblue-text"
							>
								+234-817-999-9961
							</a>
							<br />
						
						</div>

						<div class="last">
							<span class="blackspan">
								<i
									class="fa
                                fa-envelope-o skyblue-text me-2"
								></i>
								Email:
							</span>{" "}
							<a
								href="mailto:info@ivoryhomesng.com"
								className="navyblue-text"
							>
								info@ivoryhomesng.com
							</a>
						</div>

						<div className="last">
							<span className="blackspan">
								<i
									className="fa
                                fa-map-marker skyblue-text me-2"
								></i>
								Address:
							</span>{" "}
							Block A3, Office 471, <br /> Eastland Complex, Abraham Adesanya
							<br />
							Lekki-Ajah, Lagos Nigeria.
						</div>
					</div>
					<div className="dd">
						<ContactForm />
					</div>
				</div>
			</div>
			<div className="">
				<iframe
					width="100%"
					height="300px"
					frameborder="0"
					style={{ border: "0" }}
					referrerpolicy="no-referrer-when-downgrade"
					src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAmdLOTumrLulAvNgiMsZ0YlkuO02C5cmc&q=Eastland+Complex,ajah+Nigeria"
					allowfullscreen
				></iframe>
			</div>
		</div>
	);
}
