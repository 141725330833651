import React, { useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import angle_down from "../../assets/images/angle.svg";
import angle_up from "../../assets/images/down-arrow.svg";
import open from "../../assets/images/close.svg";
import close from "../../assets/images/menu.svg";
import Logo from "./components/logo";
import "./style.css";

export default function Header(props) {
	const close_menu = useRef();
	const open_menu = useRef();
	const location = useLocation();
	const nav = useRef();
	useEffect(() => {
		for (
			let i = 0;
			i < document.getElementsByClassName("targetNav").length;
			i++
		) {
			document
				.getElementsByClassName("targetNav")
				[i].addEventListener("mouseenter", (event) => {
					for (
						let n = 0;
						n < document.getElementsByClassName("targetNav").length;
						n++
					) {
						if (
							event.currentTarget.attributes.class.ownerElement.childNodes[0]
								.data ==
							document.getElementsByClassName("targetNav")[n].attributes.class
								.ownerElement.childNodes[0].data
						) {
							document
								.getElementsByClassName("targetNav")
								[n].lastElementChild.classList.toggle("d-none");
							document
								.getElementsByClassName("targetNav")
								[n].firstElementChild.classList.toggle("d-none");
							document
								.getElementsByClassName("targetNav")
								[n].children[1].classList.toggle("d-none");
						} else {
							if (
								!document
									.getElementsByClassName("targetNav")
									[n].lastElementChild.className.includes("d-none")
							) {
								document
									.getElementsByClassName("targetNav")
									[n].lastElementChild.classList.toggle("d-none");
							}
							if (
								!document
									.getElementsByClassName("targetNav")
									[n].children[1].className.includes("d-none")
							) {
								document
									.getElementsByClassName("targetNav")
									[n].children[1].classList.toggle("d-none");
							}
							if (
								document
									.getElementsByClassName("targetNav")
									[n].firstElementChild.className.includes("d-none")
							) {
								document
									.getElementsByClassName("targetNav")
									[n].firstElementChild.classList.toggle("d-none");
							}
						}
					}
				});
		}
		for (
			let i = 0;
			i < document.getElementsByClassName("targetNav").length;
			i++
		) {
			document
				.getElementsByClassName("targetNav")
				[i].addEventListener("mouseleave", (event) => {
					for (
						let n = 0;
						n < document.getElementsByClassName("targetNav").length;
						n++
					) {
						if (
							event.currentTarget.attributes.class.ownerElement.childNodes[0]
								.data ==
							document.getElementsByClassName("targetNav")[n].attributes.class
								.ownerElement.childNodes[0].data
						) {
							document
								.getElementsByClassName("targetNav")
								[n].lastElementChild.classList.toggle("d-none");
							document
								.getElementsByClassName("targetNav")
								[n].firstElementChild.classList.toggle("d-none");
							document
								.getElementsByClassName("targetNav")
								[n].children[1].classList.toggle("d-none");
						} else {
							if (
								!document
									.getElementsByClassName("targetNav")
									[n].lastElementChild.className.includes("d-none")
							) {
								document
									.getElementsByClassName("targetNav")
									[n].lastElementChild.classList.toggle("d-none");
							}
							if (
								!document
									.getElementsByClassName("targetNav")
									[n].children[1].className.includes("d-none")
							) {
								document
									.getElementsByClassName("targetNav")
									[n].children[1].classList.toggle("d-none");
							}
							if (
								document
									.getElementsByClassName("targetNav")
									[n].firstElementChild.className.includes("d-none")
							) {
								document
									.getElementsByClassName("targetNav")
									[n].firstElementChild.classList.toggle("d-none");
							}
						}
					}
				});
		}

		window.addEventListener("scroll", function () {
			const header = document.getElementById("sticky-header");
			const getAllTag = document.getElementsByTagName("nav");
			const headerHeight = header.clientHeight;
			const scrollPosition = window.scrollY;

			if (scrollPosition >= headerHeight) {
				header.style.position = "fixed";
				header.style.zIndex = "145px";
				header.style.color = "var(--navyblue) !important";
				header.style.top = "0";
				for (let i = 0; i < getAllTag.length; i++) {
					getAllTag[i].style.backgroundColor = "red !important ";
				}
			} else {
				header.style.backgroundColor = "var(--white)";
				header.style.position = "relative";
			}
		});
	}, []);
	function cleanupToggle() {
		if (!nav.current.className.includes("d-none")) {
			nav.current.classList.toggle("d-none");
			open_menu.current.classList.toggle("d-none");
			close_menu.current.classList.toggle("d-none");
		}
	}
	function closeMenu(event) {
		nav.current.classList.toggle("d-none");
		event.target.classList.toggle("d-none");
		open_menu.current.classList.toggle("d-none");
	}
	function OpenMenu(event) {
		event.target.classList.toggle("d-none");
		nav.current.classList.toggle("d-none");
		close_menu.current.classList.toggle("d-none");
	}

	return (
		<div className="position-relative stick_to_top">
			{props.hidenav ? (
				""
			) : (
				<div
					id="sticky-header"
					style={{ transition: "all  2s" }}
					className={
						"" +
						(location.pathname != "/"
							? "w-100 d-block d-md-flex justify-content-between align-items-center py-2 nav_bar"
							: " w-100 d-block d-md-flex justify-content-between align-items-center py-2 nav_bar")
					}
				>
					<div className="d-flex justify-content-between align-items-center ">
						{/* logo bar */}
						<div>
							<NavLink to="/">
								<Logo />
							</NavLink>
							<div className="ms-3 pb-3">
								<small>
									<small>RC NO: 1748963</small>
								</small>{" "}
							</div>
						</div>

						<div className="me-3 d-block d-md-none menu_image">
							<img
								src={open}
								ref={close_menu}
								style={{ transition: "all  1s" }}
								className="d-none"
								onClick={closeMenu}
								height="40"
								width="auto"
								alt="open buttton"
							/>
							<img
								src={close}
								ref={open_menu}
								style={{ transition: "all  1s" }}
								className=""
								onClick={OpenMenu}
								alt="close button"
								height="40"
								width="auto"
							/>
						</div>
					</div>

					<div
						ref={nav}
						className="pe-3  blur-navigation-background  separator d-none d-md-flex  navyblue-text"
					>
						<div className="d-flex d-md-none justify-content-end">
							<img
								src={open}
								ref={close_menu}
								style={{ transition: "all  1s" }}
								className="d-none"
								onClick={closeMenu}
								height="40"
								width="auto"
								alt="open"
							/>
						</div>
						<nav className="px-2">
							{" "}
							<NavLink
								to="/"
								onClick={cleanupToggle}
								className="navyblue-text"
							>
								Home
							</NavLink>
						</nav>

						<nav className="px-2 position-relative targetNav">
							Properties{" "}
							<span>
								<img
									src={angle_down}
									height="20px"
									alt="down"
									width="auto"
								/>
							</span>
							<span className="d-none">
								<img
									src={angle_up}
									height="20px"
									alt="down"
									width="auto"
								/>
							</span>
							<div className="dropdown_box p-2 pb-4 rounded d-none">
								<NavLink
									to="properties/lagos"
									onClick={cleanupToggle}
								>
									<nav className="blue-border py-1 navyblue-text">Lagos</nav>
								</NavLink>
								<NavLink
									to="properties/United Kingdom"
									onClick={cleanupToggle}
								>
									<nav className="blue-border py-1 navyblue-text">
										United Kingdom
									</nav>
								</NavLink>
								<NavLink>
									<nav className="blue-border py-1 navyblue-text">...</nav>
								</NavLink>
							</div>
						</nav>
						<nav className="px-2 position-relative targetNav ">
							Estate
							<span>
								<img
									src={angle_down}
									height="20px"
									alt="down"
									width="auto"
								/>
							</span>
							<span className="d-none">
								<img
									src={angle_up}
									height="20px"
									alt="down"
									width="auto"
								/>
							</span>
							<div className="dropdown_box p-2 pb-4 rounded d-none">
								<nav className="blue-border py-1 navyblue-text">
									Royal Dynasty Estate
								</nav>
								<nav className="blue-border py-1 navyblue-text">
									Ndioba Estate
								</nav>
								<nav className="blue-border py-1 navyblue-text">...</nav>
							</div>
						</nav>
						<nav className="px-2 position-relative targetNav">
							Construction
							<span>
								<img
									src={angle_down}
									height="20px"
									alt="down"
									width="auto"
								/>
							</span>
							<span className="d-none">
								<img
									src={angle_up}
									height="20px"
									alt="down"
									width="auto"
								/>
							</span>
							<div className="dropdown_box p-2 pb-4 rounded d-none">
								<NavLink
									onClick={cleanupToggle}
									to="construction/lagos"
								>
									<nav className="blue-border py-1 navyblue-text">Lagos</nav>
								</NavLink>

								<nav className="blue-border py-1 navyblue-text">...</nav>
								<nav className="blue-border py-1 navyblue-text">...</nav>
							</div>
						</nav>
						{/* <nav className="px-2">
              <NavLink to="blog" className="navyblue-text">
                Blog
              </NavLink>{" "}
            </nav> */}
						<nav className="px-2 ">
							<NavLink
								to="faqs"
								onClick={cleanupToggle}
								className="navyblue-text"
							>
								FAQs
							</NavLink>
						</nav>
						<nav className="px-2">
							<NavLink
								to="contact"
								onClick={cleanupToggle}
								className="navyblue-text"
							>
								Contact
							</NavLink>{" "}
						</nav>
						<nav className="px-2">
							<NavLink
								to="about"
								onClick={cleanupToggle}
								className="navyblue-text"
							>
								About
							</NavLink>{" "}
						</nav>
						<nav className="px-2 mt-4 mt-md-0">
							<NavLink
								to="inspection"
								onClick={cleanupToggle}
								className="navyblue-text"
							>
								<button className="px-3 py-3 py-md-0 btn-success btn m-0 p-0">
									Book Us for Inspection
								</button>
							</NavLink>{" "}
						</nav>
					</div>
				</div>
			)}
		</div>
	);
}
