import React, { useEffect, useState } from "react";
import selling from "../../../assets/images/now selling.jpg";
import land from "../../../assets/images/land.jpg";
import closeX from "../../../assets/images/close-x.svg";

import "./pop.css";
function PopUp({ recent_project, closeRecentComponentBar }) {
	const [count, setCount] = useState(0);

	const data = [
		{
			name: "Ndioba Estate",
			location: "Asaba-Delta State, Off Ogwashi-Ibuzor Road",
			pricing: {
				500: {
					size: "500",
					price: "3.5 Million",
				},
				300: {
					size: "300",
					price: "2.5 Million",
				},
			},
			advert_img: selling,
		},
		{
			name: "Umu-Eze Estate",
			location: "Imo State, Ohaji-Egbema Oguta",
			pricing: {
				500: {
					size: "500",
					price: "4 Million",
				},
			},
			advert_img: land,
		},
	];

	useEffect(() => {
		console.log(data, "data", count, "count");
		const interval = setInterval(() => {
			if (count >= 1) {
				setCount(() => 0);
			} else {
				setCount(() => count + 1);
			}
		}, 4000);

		return () => {
			clearInterval(interval);
		};
	}, [count]);

	return (
		<div>
			<div
				className="recent-project-parent d-none"
				ref={recent_project}
			>
				<div className="recent-project px-2 rounded pb-4 pb-0 ">
					<div className="d-flex justify-content-end">
						<span
							className=" "
							onClick={(event) => {
								closeRecentComponentBar(event);
							}}
						>
							<img
								src={closeX}
								alt={data[count].name}
								height="40px"
								width="auto"
							/>
						</span>
					</div>
					<div className="p-2  rounded">
						<div>
							<img
								src={data[count].advert_img}
								height="200px"
								width="100%"
								style={{ objectFit: "cover" }}
								alt="Ndioba Estate, Asaba"
								className="img-border"
							/>
						</div>
						<div className="d-flex justify-content-center align-items-center">
							<button className="px-2 border-0 py-1 general_button_color_navybg">
								NOW SELLING
							</button>
						</div>
						<div
							className="d-flex align-items-between p-2 justify-items-center mt-3"
							style={{ border: "1px solid var(--grey)" }}
						>
							<div className="flex-fill ">
								<div className="">
									<span className="fw-bolder fs-2">
										{data[count].name ? data[count].name : ""}
									</span>
									<br />
									<small>
										{" "}
										{data[count].location ? data[count].location : ""}
									</small>
								</div>
							</div>
							<div
								className="flex-fill ps-2"
								style={{ borderLeft: "1px solid var(--grey)" }}
							>
								<div className="pb-1 fw-bold">Pre-launch Price</div>
								<div
									className="row"
									style={{ borderTop: "1px solid var(--grey)" }}
								>
									{data[count]?.pricing[500]?.price && (
										<div
											className="col d-flex align-items-center flex-column justify-content-center"
											style={{ borderRight: "1px solid var(--grey)" }}
										>
											<div className="fw-bold fs-2 price_size">
												{data[count]?.pricing[500]?.price}
											</div>
											<div> 500sqm</div>
										</div>
									)}
									{data[count]?.pricing[300]?.price && (
										<div className="col d-flex align-items-center flex-column justify-content-center ">
											<div className="fw-bold fs-2 price_size">
												{data[count]?.pricing[300]?.price}
											</div>
											<div> 300sqm</div>
										</div>
									)}
								</div>{" "}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PopUp;
