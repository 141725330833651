import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import "./fallback.css";
import loading from "../../assets/images/animate_loading.gif";


export default function Fallback() {
	return (
		<div
			className="top-0 start-0 end-0 bottom-0 d-flex loading justify-content-center align-items-center "
			style={{ height: "100vh" }}
		>
			<img src={loading} alt="loading icon"  width="auto" height="30px" />
		</div>
	);
}
