import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import telephone from "../../../assets/images/telephone.svg";
import Icons from "../../home/components/icons";
import euro from "../../../assets/images/new icon batch/euro.svg";
import data from "./data";
import "./modal.css";
import naira_pink from "../../../assets/images/naira.svg";
import dollar_pink from "../../../assets/images/dollar-sign_blue.svg";
import bathtub from "../../../assets/images/new icon batch/bathtub.svg";
import bed from "../../../assets/images/new icon batch/bed.svg";
import location from "../../../assets/images/new icon batch/location.svg";
import size from "../../../assets/images/new icon batch/size.svg";

function My_Modal(props) {
	const { title, id } = useParams();
	const [items, setItems] = useState([]);

	useEffect(() => {
		setItems((v) => {
			return data.Projects.filter((v) => {
				return v.title == title;
			});
		});
	}, []);

	function ChangeImage(event) {
		const element = document.getElementById("big-screen");
		let unique1 = document.getElementsByClassName("unique-1");
		let lengther = document.getElementsByClassName("lengther");
		unique1 = Array.from(unique1);
		lengther = Array.from(lengther);

		//For the outline
		unique1.forEach((v) => {
			v.classList.remove("active-outline");
		});
		event.target.classList.add("active-outline");
		// FOr the indeicator

		lengther.forEach((v) => {
			if (v.getAttribute("id") == event.target.getAttribute("item")) {
				v.classList.add("lengther-color");
			} else {
				v.classList.remove("lengther-color");
			}
		});

		const newsrc = event.target;

		const newsrc2 = event.target.nextElementSibling;

		if (newsrc != null) {
			element.children[0].src = newsrc.getAttribute("src");
		}
		if (newsrc2 != null) {
			element.children[1].src = newsrc2.getAttribute("src");
		}
	}
	return (
		<div>
			{items.length > 0 ? (
				<div className="light-background d-flex flex-column ">
					<div>
						<div className="d-flex mt-4 mb-2 px-2 align-items-center justify-content-between">
							<div className="d-flex d-md-block flex-column py-3">
								<span
									className="me-2 title-limiter px-1 fw-bold be-vietnamPro navyblue-text"
									style={{ scrollBehavior: "smooth" }}
								>
									{items[0].title}
								</span>
								<div className=" mt-2 d-flex  align-items-center ">
									<img
										src={location}
										className=""
										width="20px"
										alt=""
									/>
									<div>{items[0].Location}</div>
								</div>
							</div>
							<div className="d-flex  px-1 flex-column">
								<div className="fw-bold navyblue-text">Price</div>
								<div className="mt-2">
									<span className="navy-blue ">
										<i class="fa-solid fa-naira-sign"></i>{" "}
										<img
											src={
												items[0].currency == "naira"
													? naira_pink
													: items[0].currency == "dollar"
													? dollar_pink
													: euro
											}
											className="naira"
											alt=""
										/>
										{items[0].Price}
									</span>
								</div>
							</div>
						</div>

						<div className="d-flex align-items-center rounded flex-column mx-4 rounded navyblue-background pt-2 ">
							<div
								id="big-screen"
								className="d-flex rounded-lg justify-content-center align-items-center"
							>
								<img
									src={items[0].image_gallery[0]}
									className="big-display  p-2 rounded"
									alt=""
								/>
								<img
									src={items[0].image_gallery[1]}
									className=" big-display rounded p-2 d-none d-md-block"
									alt=""
								/>
							</div>
							<div className=" thumbPrint_overflow rounded">
								{items[0].image_gallery.map((v, index) => {
									return (
										<img
											className="rounded m-2 unique-1 "
											onClick={ChangeImage}
											src={v}
											item={index}
											style={{ objectFit: "cover" }}
											alt=""
										/>
									);
								})}
							</div>
							<div className="d-flex justify-content-center my-3 p-2 rounded light-skyblue-background align-items-center">
								<div className="d-flex">
									{items[0].image_gallery.map((v, index) => {
										return (
											<div
												className="lengther m-2 rounded"
												id={index}
											></div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
					<div className="mx-4">
						<div className="row my-2 remove-gutter  rounded">
							<div className="col-sm-12 rounded col-md-6  py-3 px-3">
								<div className=" p-3 rounded">
									<div>
										<header className="fw-bolder">Description</header>
										<p>
											Discover your own piece of paradise with the Seaside
											Serenity Villa. T With an open floor plan, breathtaking
											ocean views from every room, and direct access to a
											pristine sandy beach, this property is the epitome of
											coastal living.
										</p>
									</div>
									<div className="row ">
										<div className="col-sm-6 col-md-4">
											<div>
												<img
													src={bed}
													className="me-1"
													width="20px"
													alt=""
												/>
												Bedrooms
											</div>
											<div className="fw-bold">{items[0].bedroom}</div>
										</div>
										<div className="col-sm-6 col-md-4">
											<div>
												<img
													src={bathtub}
													className="me-1"
													width="20px"
													alt=""
												/>
												Bathrooms
											</div>
											<div className="fw-bold">{items[0].bathroom}</div>
										</div>
										<div className="col-sm-12 col-md-4 mt-4 mt-md-0">
											<div>
												<img
													src={size}
													className="me-1"
													width="20px"
													alt=""
												/>
												Area
											</div>
											<div>Not specified</div>
										</div>
									</div>
								</div>
							</div>
							<div className=" col-sm-12 rounded col-md-6 p-3  navyblue-background">
								{/* <div></div> */}
								<div className="p-3  rounded">
									<header className="fw-bold text-light mb-2">
										Key Features and Amenities
									</header>
									{items[0].Description.map((v) => {
										return <div className="features p-2 mb-2">{v}</div>;
									})}
								</div>
							</div>
						</div>
						<div className="row remove-gutter mb-2 rounded">
							<div className=" col-sm-12 col-md-6 rounded  p-4">
								{/* <div></div> */}
								<div className="p-3  rounded">
									<div className="fw-bold">Inquire about {items[0].title}</div>
									<div>
										Interested in this property? Fill out the form below, and
										our real estate experts will get back to you with more
										details, including scheduling a viewing and answering any
										questions you may have.
									</div>
								</div>
							</div>
							<div
								className="row col-sm-12 col-md-6 rounded  align-items-center p-4  justify-content-center remove-gutter"
								style={{ maxWidth: "1200px" }}
							>
								<div className="p-3 rounded ">
									<div className="">
										<form action="">
											<div className="form-input">
												<textarea
													name="message"
													className="form-control "
													placeholder="Enter your request here"
													id=""
													cols="10"
													rows="10"
												></textarea>
											</div>
											<div className="form-input mt-2">
												<input
													className="form-control"
													type="email"
													name="email"
													placeholder="Enter your email "
													id=""
												/>
											</div>
											<div className="form-input mt-2">
												<input
													className="form-control"
													type="number"
													name="number"
													placeholder="Enter your mobile number"
													id=""
												/>
											</div>
											<div className="form-input mt-3">
												<button className=" form-control general_button_color text-center light-text">
													Contact us now
												</button>
											</div>
										</form>
									</div>
								</div>

								<div className="mt-3 col-sm-12  col-md-6 mt-4">
									<div className="d-flex align-items-center justify-content-center mb-3">
										<img
											src={telephone}
											className="me-2"
											height="25px"
											alt=""
										/>
										<h6> Call us on</h6>
									</div>
									<div className="d-flex align-items-center flex-column justify-content-center">
										<p className=" px-2">+234-817-999-9953</p>
										<p className="px-2">+234-817-999-9961</p>
									
									</div>
									<Icons title="Reach our agents on social media" />
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div>NOT FOUND</div>
			)}
		</div>
	);
}

export default My_Modal;
