import React from "react";

import { useForm, ValidationError } from "@formspree/react";

export default function ContactForm() {
	const [state, handleSubmit] = useForm("maygbwdd");
	if (state.succeeded) {
		return (
			<p className="p-2 rounded mt-3">
				<span className="light-background">
					Thank you for submitting your inquiry! We appreciate your interest and
					will get back to you as soon as possible.
				</span>{" "}
			</p>
		);
	}
	return (
		<form
			className="About-form"
			onSubmit={handleSubmit}
			name="contact"
		>
			<input
				type="hidden"
				name="form-name"
				value="contact"
			/>

			<div className="form-group">
				<input
					type="text"
					name="name"
					id="name"
					className="form-control"
					placeholder="Name"
				/>
				<ValidationError
					prefix="Name"
					field="name"
					errors={state.errors}
				/>
			</div>
			<div>
				<input
					type="email"
					name="email"
					className="form-control"
					placeholder="Email"
				/>
				<ValidationError
					prefix="Email"
					field="email"
					errors={state.errors}
				/>
			</div>
			<div>
				<textarea
					name="messsage"
					id="message"
					cols="30"
					rows="10"
					placeholder="Message"
				/>
				<ValidationError
					prefix="Message"
					field="message"
					errors={state.errors}
				/>
			</div>
			<div className="button-div mt-2">
				<div className="icons d-flex align-items-center">
					<a
						href="https://twitter.com/Ivory_homes_ltd?t=JazenYeTUemyd4jvOT9szw&s=08"
						target="_blank"
					>
						<i className="fa fa-twitter navyblue-text"></i>
					</a>

					<a
						href=" https://wa.me/message/ZHKL2Z5OXK3QI1"
						target="_blank"
					>
						<i className="fa fa-linkedin navyblue-text"></i>
					</a>
					<a
						href="https://www.instagram.com/ivoryhomesandestates/?igshid=OGQ5ZDc2ODk2ZA=="
						target="_blank"
					>
						<i className="fa fa-instagram navyblue-text"></i>
					</a>
				</div>
				<button
					className="btn btn-sm btn-outline-light general_button_color light-text inactive"
					type="submit"
					disabled={state.submitting}
				>send
				</button>
			</div>
		</form>
	);
}
