import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Fallback from "./pages/fallbacks/fallback";
import Home from "./pages/home/index.jsx";
import My_Modal from "./pages/properties/projectPage/modal.js";
// const  Home = lazy(() => import("./pages/home/index.jsx"));
const Blog = lazy(() => import("./pages/blog/blog"));
const Inspection = lazy(() => import("./pages/home-inspection/inspection"));
const BuildUp = lazy(() => import("./pages/home/home"));
const Properties = lazy(() => import("./pages/properties"));
const Construction = lazy(() => import("./pages/construction"));
const About = lazy(() => import("./pages/aboutUs"));
const Faqs = lazy(() => import("./pages/faq"));
const Contact = lazy(() => import("./pages/contactUs/contact"));

function App() {
	return (
		<div className="App">
			<Suspense fallback={<Fallback />}>
				<Routes>
					{/* <Route
						path="/landing"
						element={<HeaderCompnt />}
					>
						<Route
							index
							element={<Landing />}
						/>
					</Route> */}
					<Route
						path="/"
						element={<Home />}
					>
						<Route
							index
							element={<BuildUp />}
						/>
						<Route
							path="/ivory-homes"
							element={
								<Navigate
									replace
									to="/"
								/>
							}
						/>

						<Route
							path="/properties/:id"
							element={<Properties />}
						/>
						<Route
							path="/properties/view/:title"
							element={<My_Modal />}
						/>
						<Route
							path="/properties/:id/:title"
							element={<My_Modal />}
						/>
						{/* <Route
							path="/properties/:id/:building"
							element={<Properties />}
						/> */}
						<Route
							path="construction/:id"
							element={<Construction />}
						/>
						<Route
							path="about"
							element={<About />}
						/>
						<Route
							path="blog/:name/:number"
							element={<Blog />}
						/>
						<Route
							path="faqs"
							element={<Faqs />}
						/>
						<Route
							path="contact"
							element={<Contact />}
						/>
						<Route
							path="inspection"
							element={<Inspection />}
						/>

						{/* <Route
							path="/*"
							element={
								<Navigate
									replace
									to="/"
								/>
							}
						/> */}
					</Route>
				</Routes>
			</Suspense>
		</div>
	);
}

export default App;
