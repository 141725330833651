import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import rightArrow from "../../../assets/images/right-arrow.svg";
import data from "../../blog/data";
import "../blog.css";

let i = 0;
let v = 3;

export default function Blog() {
	const ref = useRef(null);
	const [imgLibrary, setImgLibrary] = useState([]);
	useEffect(() => {
		let fetchedpost = data.blogposts.slice(0, 3);

		setImgLibrary(fetchedpost);

		setInterval(() => {
			LoopThrough();
		}, 3000);
		let config = { attributes: true, childList: true };

		function callback(multation) {
			multation[0].target.parentElement.parentElement.animate(
				[
					// keyframes
					{ transform: "translateX(0px)" },
					{ transform: "translateX(20px)" },
					{ transform: "translateX(15px)" },
					{ transform: "translateX(10px)" },
					{ transform: "translateX(5px)" },
					{ transform: "translateX(0px)" },
					{ transform: "translateX(-5px)" },
					{ transform: "translateX(-10px)" },
				],
				{
					// timing options
					duration: 200,
					iterations: 1,
				}
			);
		}

		let newobserver = new MutationObserver(callback);
		setTimeout(() => {
			newobserver.observe(ref.current, config);
		}, 2000);
	}, []);

	function LoopThrough() {
		if (v >= data.blogposts.length) {
			i = -1;
			v = 2;
		}
		i = i + 1;
		v = v + 1;
		let sliced = data.blogposts.slice(i, v).reverse();
		setImgLibrary(() => {
			return sliced;
		});
	}

	return (
		<div className="mt-2 pt-2 pb-4 px-1 container position-relative main-container_ rounded">
			<div className="position-relative lifter_ mt-4">
				<h2 className="text-center mb-4">
					<span className="mx-auto read_article_header navyblue-text">
						Read our article to stay afloat !
					</span>{" "}
				</h2>
				<div id="create_slide ">
					{imgLibrary.length > 0 && (
						<div
							className="rescale_carousal_ flex-column flex-lg-row"
							id="rescale_carousal"
						>
							<div
								className="carousel_item_left_ rounded "
								id="imgg"
							>
								<div className="d-flex justify-content-center mt-3">
									<img
										src={imgLibrary[0].featured_image}
										style={{ objectFit: "cover" }}
										alt="product image"
										className="rounded"
									/>
								</div>
								<div className="px-4">
									<h6 className="mt-2 navyblue-text">{imgLibrary[0].title}</h6>
									{/* <p>{imgLibrary[0].excerpt}</p> */}
									<div className="d-flex justify-content-center">
										<Link
											to={
												"../blog/" +
												imgLibrary[0].title +
												"/" +
												imgLibrary[0].id
											}
										>
											<button className="btn btn-sm general_button_color light-text">
												Read more
											</button>
										</Link>
									</div>
								</div>
							</div>
							<div className="carousel_item_center_  d-none d-lg-block rounded">
								<div className="d-flex justify-content-center mt-3">
									<img
										src={imgLibrary[1].featured_image}
										alt="product image"
										className="rounded"
										style={{ objectFit: "cover" }}
									/>
								</div>
								<div className="px-4">
									<h6 className="mt-2 navyblue-text">{imgLibrary[1].title}</h6>
									{/* <p>{imgLibrary[1].excerpt}</p> */}
									<div className="d-flex justify-content-center">
										<Link
											to={
												"../blog/" +
												imgLibrary[1].title +
												"/" +
												imgLibrary[1].id
											}
										>
											<button className="btn btn-sm general_button_color light-text">
												Read more
											</button>
										</Link>
									</div>
								</div>
							</div>
							<div className="carousel_item_right_  d-none d-lg-block rounded">
								<div className="d-flex justify-content-center  mt-3">
									<img
										src={imgLibrary[2].featured_image}
										alt="product image"
										ref={ref}
										className="rounded"
									/>
								</div>
								<div className="px-4">
									<h6 className="mt-2 navyblue-text">{imgLibrary[2].title}</h6>
									{/* <p className="skyblue-text">{imgLibrary[2].excerpt}</p> */}
									<div className="d-flex justify-content-center">
										<Link
											to={
												"../blog/" +
												imgLibrary[2].title +
												"/" +
												imgLibrary[2].id
											}
										>
											<button className="btn btn-sm general_button_color light-text">
												Read more
											</button>
										</Link>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
				<div className="tagIcon_ToLeft_">
					<button
						className="btn-sm btn navyblue-background"
						onClick={LoopThrough}
					>
						<img
							src={rightArrow}
							height="20px"
							alt=""
						/>
					</button>
				</div>
			</div>
		</div>
	);
}
