import React from "react";
import LOGO from "../../../assets/images/LOGO.svg";

export default function Logo() {
	return (
		<div className=" ms-3 d-flex align-items-center  justify-content-start">
			<span className=" rounded px-3 py-1 d-flex align-items-center justify-content-center">
				<span className="logo_image  me-1">
					<img
						src={LOGO}
						alt=""
					/>
				</span>
				<span className=" me-1">
					<div className="navyblue-text ivoryhomes fw-bold">
						Ivory Homes Ltd
					</div>
				</span>
			</span>
		</div>
	);
}
