import React from "react";
import whatsapp from "../../../assets/images/whatsapp.svg";
import twitter from "../../../assets/images/twitter.svg";
import instagram from "../../../assets/images/instagram.svg";
export default function Icons(props) {
	return (
		<div className="">
			<h6 className="text-center">
				<small>{props.title}</small>
			</h6>
			<div className="d-flex justify-content-center gap-4 align-items-center mt-2">
				<div className="icon">
					<a
						href=" https://wa.me/message/ZHKL2Z5OXK3QI1"
						target="_blank"
					>
						<img
							src={whatsapp}
							height="30px"
							alt="whatsapp ivoryhomeng"
						/>
					</a>
				</div>
				<div className="icon">
					<a
						href="https://twitter.com/Ivory_homes_ltd?t=JazenYeTUemyd4jvOT9szw&s=08"
						target="_blank"
					>
						<img
							src={twitter}
							height="30px"
							alt="twitter ivoryhomesng"
						/>
					</a>
				</div>

				<div className="icon">
					<a
						href=" https://www.instagram.com/ivoryhomesandestates/?igshid=OGQ5ZDc2ODk2ZA=="
						target="_blank"
					>
						<img
							src={instagram}
							height="30px"
							alt="instagram ivoryhomesng"
						/>
					</a>
				</div>
			</div>
		</div>
	);
}
