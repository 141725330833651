import image from "../../../assets/images/houses/1.jpg";
import image2 from "../../../assets/images/houses/2.jpg";
import image3 from "../../../assets/images/houses/3.jpg";
import image4 from "../../../assets/images/houses/4.jpg";

import house1_0 from "../../../assets/images/All houses/house 1/image-000-min.jpg";
import house1_1 from "../../../assets/images/All houses/house 1/image-001-min.jpg";
import house1_2 from "../../../assets/images/All houses/house 1/image-002-min.jpg";
import house1_4 from "../../../assets/images/All houses/house 1/image-004-min.jpg";
import house1_5 from "../../../assets/images/All houses/house 1/image-005-min.jpg";
import house1_6 from "../../../assets/images/All houses/house 1/image-006-min.jpg";
import house1_7 from "../../../assets/images/All houses/house 1/image-007-min.jpg";
import house1_8 from "../../../assets/images/All houses/house 1/image-008-min.jpg";
import house1_9 from "../../../assets/images/All houses/house 1/image-009-min.jpg";
import house2_0 from "../../../assets/images/All houses/house 2/image-010-min.jpg";
import house2_1 from "../../../assets/images/All houses/house 2/image-011-min.jpg";
import house2_2 from "../../../assets/images/All houses/house 2/image-012-min.jpg";
import house2_3 from "../../../assets/images/All houses/house 2/image-013-min.jpg";
import house2_4 from "../../../assets/images/All houses/house 2/image-014-min.jpg";
import house2_5 from "../../../assets/images/All houses/house 2/image-015-min.jpg";
import house3_0 from "../../../assets/images/All houses/house 3/image-016-min.jpg";
import house3_1 from "../../../assets/images/All houses/house 3/image-017-min.jpg";
import house3_2 from "../../../assets/images/All houses/house 3/image-018-min.jpg";
import house3_3 from "../../../assets/images/All houses/house 3/image-019-min.jpg";
import house3_4 from "../../../assets/images/All houses/house 3/image-020-min.jpg";
import house4_0 from "../../../assets/images/All houses/house 4/image-021-min.jpg";
import house4_1 from "../../../assets/images/All houses/house 4/image-022-min.jpg";
import house4_2 from "../../../assets/images/All houses/house 4/image-023-min.jpg";
import house4_3 from "../../../assets/images/All houses/house 4/image-024-min.jpg";
import house4_4 from "../../../assets/images/All houses/house 4/image-025-min.jpg";
import house4_5 from "../../../assets/images/All houses/house 4/image-026-min.jpg";
import house4_6 from "../../../assets/images/All houses/house 4/image-027-min.jpg";
import house4_7 from "../../../assets/images/All houses/house 4/image-028-min.jpg";
import house4_8 from "../../../assets/images/All houses/house 4/image-029-min.jpg";
import house5_0 from "../../../assets/images/All houses/house 5/image-030-min.jpg";
import house5_1 from "../../../assets/images/All houses/house 5/image-031-min.jpg";
import house5_2 from "../../../assets/images/All houses/house 5/image-032-min.jpg";
import house5_3 from "../../../assets/images/All houses/house 5/image-033-min.jpg";

// UK PROPERTIES
import house6_0 from "../../../assets/images/uk prop/1.jpg";
import house6_1 from "../../../assets/images/uk prop/2.jpg";
import house6_2 from "../../../assets/images/uk prop/3.jpg";
import house6_3 from "../../../assets/images/uk prop/4.jpg";
import house6_4 from "../../../assets/images/uk prop/5.jpg";
import house6_5 from "../../../assets/images/uk prop/6.jpg";
import house7_0 from "../../../assets/images/uk prop/group2/1.jpg";
import house7_1 from "../../../assets/images/uk prop/group2/2.jpg";
import house7_2 from "../../../assets/images/uk prop/group2/3.jpg";
import house7_3 from "../../../assets/images/uk prop/group2/4.jpg";
import house7_4 from "../../../assets/images/uk prop/group2/5.jpg";
import house7_5 from "../../../assets/images/uk prop/group2/6.jpg";
import house7_6 from "../../../assets/images/uk prop/group2/7.jpg";
import house7_7 from "../../../assets/images/uk prop/group2/8.jpg";

export default {
	Projects: [
        
        {
			title: "Serviced  Bedroom Apartment UK",

			Display_image:  house6_0, 
			Location: "Creston",
			Description: [
                "1 Bedroom serviced flat",
                "Creston, 10 minutes drive from Manchester",
                "Suitable for Airbnb",
                "Returns on Investment: 5 years"
              ],
			Price: "180,000",
			displayDigit: "180K",
			sc: 2,
			image_gallery: [
               
                house6_1,
                house6_2,
                house6_3,
                house6_4,
                house6_5,
                house7_0,
                house7_1,
                house7_2,
                house7_3,
                house7_4,
                house7_5,
                house7_6,
                house7_7
			],
			state: "United Kingdom",
			type: "solid",
			currency: "euro",
            bedroom: 1,
            bathroom: 1
		},,
		{
			title: "Governor Consent",

			Display_image: house1_0, 
			Location: "Atlantic View Estate, Igbo-Efon",
			Description: ["3 units of 4-bedroom Service Terrace"],
			Price: "950,000,000",
			displayDigit: "95M",
			sc: 2,
			image_gallery: [
				house1_1,
				house1_2,
				house1_4,
				house1_5,
				house1_6,
				house1_7,
				house1_8,
				house1_9,
			],
			state: "lagos",
			type: "solid",
			currency: "naira",
            bedroom: 4,
            bathroom: 4
		},
		{
			title: "Luxury 5 bedroom Fully Detached Duplex",
			Display_image: house2_0,
			Location: "Victory Park estate, Jakande Lekki",
			Description: [
                "Fitted Kitchen",
                "Detailed finishing Wardrobes",
                "Jacuzzi",
                "CCTV",
                "Automated LightWater heater",
                "Heat extractor",
                "Ample parking space",
                "Swimming",
                "PoolCinema",
                "Gym"
            ],
			Price: "600,000,000",
			displayDigit: "600M",
			image_gallery: [house2_1, house2_2, house2_3, house2_4, house2_5],
			state: "lagos",
			type: "solid",
			currency: "naira",
            bedroom: 5,
            bathroom: 5

		},
		// https://drive.google.com/file/d/1D1cXDZ1nrlBZCrgSuywhm1XJImoDyUCt/view?usp=drive_link
		{
			title: "Fully Automated 6 Bedrooms Furnished",
			Display_image: house4_0,
			Location: "Ikoyi",
			Description: [
                "6 Bedrooms (2 of the 6 bedrooms are on the ground floor).",
                "Elevator lift",
                "2 room Boys' quarter.",
                "House Automation.",
                "Spacious Garden.",
                "Fully fitted kitchen with refrigerator and freezer, dishwasher, water heater, a spacious pantry, beautifully lit cabinets with LED lights, etc.",
                "Dining area",
                "Main lounge and a private lounge are on the ground floor.",
                "Lounges on all floors.",
                "Spacious terraces/balconies with all round view of the estate, Waterview of the lagoon.",
                "You can also enjoy a long-distance view of the Lekki-Ikoyi water bridge.",
                "Decked pool area with space for a Gazebo.",
                "Swimming pool comes with heating and massage features.",
                "Jacuzzi.",
                "Central air conditioning cooling system felt strongly in all parts of the house.",
                "The house comes with washing machine, water heaters in all bathrooms, water filtration facilities. etc.",
                "Penthouse floor with office/study.",
                "Cinema/ Movie theatre with surround sound system and other amazing features of a world class cinema.",
                "A box room that can serve as Gym.",
                "Massive Roof terraces/Balconies",
                "Video Door Bell.",
                "CCTV Cameras",
                "Intercom system around the house.",
                "Smoke Detector/alarm.",
                "Automated gate system.",
                "Nuovo integrated system.",
                "Sufficient Car park space.",
                "24/7 Electricity with backup generator.",
                "24 Hours Security",
                "Waterfront view of the ocean.",
                "The house is partly furnished with high quality furniture and decorations."
            ],
			Price: "5,000,000",
			displayDigit: "5M",
			image_gallery: [
				house4_1,
				house4_2,
				house4_3,
				house4_4,
				house4_5,
				house4_6,
				house4_7,
				house4_8,
			],
			state: "lagos",
			type: "solid",
			currency: "dollar",
            bedroom: 8,
            bathroom: 7
		},
		{
			title: "5 Bedroom Detached Duplex",

			Display_image: house3_0,
			Location: "Ikoyi",
			Description: [
                "Contemporary Architecture.",
                "CCTV Cameras",
                "Swimming pool",
                "Elevator",
                "Family Lounge",
                "Gorgeous Top-Quality Wardrobes.",
                "Stamp Concrete Floor",
                "Integrated Audio System",
                "Gorgeous Lightings",
                "Fitted Air Conditions",
                "2 floors sitting",
                "Top Quality Sanitary Wares",
                "Decent Detailed Finishing",
                "Prestigious Neighborhood",
                "Fully Fitted kitchen",
                "BQ",
                "Car park space (4/ 5cars)",
                "Laundry Room",
                "Spot lights",
                "Spacious bedrooms",
                "Serene environment"
            ],
			Price: "1,1000,000,000,000",
			displayDigit: "1.1B",

			image_gallery: [house3_1, house3_2, house3_3, house3_4],
			state: "lagos",
			type: "solid",
			currency: "naira",
            bedroom: 5,
            bathroom: 4
		},
		{
			title: "4-bedroom semi-detached house with a room Bq",

			Display_image: house5_0,
			Location: "Earls Court Estate, Ikate.",
			Description: ["5 Bedroom Detached Duplex"],
			Price: "300,000,000",
			displayDigit: "300M",
			image_gallery: [house5_1, house5_2, house5_3],
			state: "lagos",
			type: "solid",
			currency: "naira",
            bedroom: 5,
            bathroom: 4
		},
		{
			title: "Neat 3 bedroom serviced apartment ",

			Display_image: image,
			Location: "lekki, Lagos",
			Description: ["5 Bedroom Detached Duplex"],
			Price: "55,000,000",
			displayDigit: "55M",
			image_gallery: [image2, image3, image4],
			state: "lagos",
			type: "solid",
			currency: "naira",
            bedroom: 3,
            bathroom: 2
		},
	],
};
