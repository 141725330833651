import React from "react";
export default function Footer() {
	return (
		<div className=" light-text navyblue-background light-text pt-2">
			<div className="d-flex px-3 flex-column gap-4 flex-lg-row justify-content-between align-items-center  footer">
				<div
					style={{ flex: "2" }}
					className="py-4 container"
				>
					<h6 className="mb-3 text-center">Subscribe to Our Newsletters</h6>
					<form
						action=""
						className="d-flex justify-content-center"
					>
						<div className="input-group">
							<input
								type="email"
								name="email"
								placeholder="Input your e-mail"
								id=""
								className="form-control"
								required
							/>

							<button className="btn input-group-append btn-light light-text general_button_color  ">
								Subscribe
							</button>
						</div>
						<div className="d-flex justify-content-center align-items-center"></div>
					</form>
				</div>
			</div>

			<div className="light-skyblue-background py-1 darker-blue-text">
				<div className="container">
					<small>
						© Copyright <span className="fw-bold">IVORY HOMES</span> . All
						Rights Reserved.
					</small>
				</div>
			</div>
		</div>
	);
}
